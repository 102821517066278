import React, { FC } from 'react';
import classNames from 'classnames';

import IconCustom from 'common/IconCustom';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IPropsDisclaimerMessage } from './models';
import './DisclaimerMessage.scss';
import './ShadowDisclaimerMessage.scss';

const DisclaimerMessage: FC<IPropsDisclaimerMessage> = ({ content, className, isDesktop }) => (
  <div data-test="DisclaimerMessage" className="disclaimer-message">
    {isDesktop ? (
      <DangerouslySetInnerHtml
        className={classNames('disclaimer-message__content', className)}
        html={content}
      />
    ) : (
      <div className={classNames('disclaimer-message__wrapper', className)}>
        <IconCustom icon="alert" className="disclaimer-message__icon" />
        <DangerouslySetInnerHtml className="disclaimer-message__content" html={content} />
      </div>
    )}
  </div>
);

export default DisclaimerMessage;
