import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ProductsFinder from 'components/ProductsFinder';
import Button from 'common/Button';

import { IPropsTargetingSection } from './models';
import './TargetingSection.scss';
import './ShadowTargetingSection.scss';

const TargetingSection: FC<IPropsTargetingSection> = ({
  targetingSection: [
    {
      properties: {
        startQuizButtonTargeting,
        targetingText,
        targetingButton: [
          {
            properties: { ariaLabel, label, link },
          },
        ],
      },
    },
  ],
  ariaLabelPrev,
  ariaLabelNext,
  productQuizData,
  lang,
  setParentSearchState,
  callbackHandleQuizVisibility,
  handleParentAlgoliaFiltersUsed,
  handleCompleteProductsFinder,
}) => (
  <div className="targeting-section" data-test="TargetingSection">
    <div className="targeting-section__left">
      <ProductsFinder
        startQuiz={startQuizButtonTargeting}
        ariaLabelPrev={ariaLabelPrev}
        ariaLabelNext={ariaLabelNext}
        productQuizData={productQuizData}
        lang={lang}
        setParentSearchState={setParentSearchState}
        callbackHandleQuizVisibility={callbackHandleQuizVisibility}
        handleParentAlgoliaFiltersUsed={handleParentAlgoliaFiltersUsed}
        handleCompleteProductsFinder={handleCompleteProductsFinder}
      />
    </div>
    <div className="targeting-section__right">
      <div className="targeting-section__right--holder">
        <span className="targeting-section__right__title">{targetingText}</span>
        <Button
          className="targeting-section__right__button"
          link={link?.[0]?.url}
          ariaLabel={ariaLabel}
        >
          {label}
        </Button>
      </div>
    </div>
  </div>
);

export const query = graphql`
  fragment FragmentTargetingSection on TTargetingSection {
    properties {
      targetingText
      startQuizButtonTargeting {
        properties {
          ariaLabel
          boldTitle
          lightTitle
        }
      }
      targetingButton {
        ...FragmentButton
      }
    }
  }
`;

export default TargetingSection;
