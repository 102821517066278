import React, { FC, useMemo } from 'react';
import { UmbracoProductQuizResult } from '@shared/types';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import { IPropsProductQuizResultMessage } from './models';
import './ProductQuizResultMessage.scss';

const ProductQuizResultMessage: FC<IPropsProductQuizResultMessage> = ({
  quizResultMessages,
  currentSymptomId,
}) => {
  const { items } = quizResultMessages?.[0]?.properties;
  const messageToShow = useMemo(
    () =>
      items?.find(
        (element: UmbracoProductQuizResult.IStructure) =>
          element.properties.tagId === currentSymptomId
      ),
    [currentSymptomId, items]
  );

  return messageToShow?.properties && currentSymptomId ? (
    <div data-test="ProductQuizResultMessage" className="product-quiz-result-message">
      {messageToShow.properties?.title ? (
        <h2 className="product-quiz-result-message__title">{messageToShow.properties.title}</h2>
      ) : null}

      {messageToShow.properties?.text ? (
        <DangerouslySetInnerHtml
          html={messageToShow.properties.text}
          className="product-quiz-result-message__text"
        />
      ) : null}
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentQuizResultList on TProductQuizResultList {
    properties {
      items {
        properties {
          title
          text
          tagId
        }
      }
    }
  }
`;

export default ProductQuizResultMessage;
