import { FC, ReactElement } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import { IPropsCustomRefinementList } from './model';

export const CustomRefinementList: FC<IPropsCustomRefinementList> = (): ReactElement | null => null;

const ConnectedCustomRefinementList = connectRefinementList(CustomRefinementList);

export default ConnectedCustomRefinementList;
