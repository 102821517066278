import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';

import { IPropsStoreCard } from './models';
import './StoreCard.scss';

const StoreCard: FC<IPropsStoreCard> = ({ altLabel, link, image, onClickHandler }) => {
  const { url, queryString } = link[0];
  const path = queryString && queryString !== '' ? `${url}${queryString}` : url;
  const target = link?.[0]?.target;

  return (
    <div data-test="StoreCard" className="store-card">
      <a
        href={path}
        className="store-card__link"
        {...(link?.[0]?.target && { target })}
        rel="noreferrer"
        onClick={onClickHandler ? onClickHandler({ link: path, target }) : undefined}
      >
        <GatsbyImage image={image} alt={altLabel} objectFit="contain" />
      </a>
    </div>
  );
};

export default StoreCard;
